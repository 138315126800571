import styled from "styled-components";
import {
  BP,
  Rem,
} from "../../commons/Theme";

export const StyledBoxListScroller = styled.div``;

export const StyleInfoButton = styled.div`
  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.3);
  position: absolute; 
  bottom: 13px;
  right: 20px;
  background-color: white;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  text-align: center;
`;

export const StyledBoxListScrollerItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  margin: 0 auto;
  width: fit-content;

  &:after {
    content: '';
    display: block;
    min-width: ${Rem(50)};
    max-width: ${Rem(50)};
    height: 1px;

    @media (${BP.ipad}) {
      display: none;
    }
  }
`;

export const StyledBoxListScrollerItem = styled.div`
  position: relative;
  color: white;
  margin-left: ${Rem(16)};

  width: 90vw;
  height: 90vw;
  max-width: ${Rem(350)};
  max-height: ${Rem(350)};

  .gatsby-image-wrapper {
    width: 90vw;
    height: 90vw;
    max-width: ${Rem(350)};
    max-height: ${Rem(350)};
  }
`;

export const StyledBoxListScrollerItemButton = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  column-gap: 10px;
  padding: 0px 10px;
`;

export const StyledButtonShop = styled.button`
  width: auto;
`;
